<template>
  <section class="page-container">
    <div ref="toolbar" class="toolbar">
      <el-button type="primary" @click="sendGoHomeEmail"> 域名切换邮件发送 </el-button>
    </div>
  </section>
</template>

<script>
export default {
  methods:{
    async sendGoHomeEmail(){
      const me = this;
      try{
        let res = await this.axios.get("/api/admin/email/go/home")
        me.$message({
          message: '发送成功',
          type: 'success'
        });
      }catch (e){
        me.$notify.error({ title: "错误", message: e.message });
      }
    }
  }
}
</script>

<style scoped>

</style>
